import React, { useEffect, useState } from "react";
import { PrescriptionStyled } from "./Prescription.styled";
import {
  FaSearch,
  FaMicrophone,
  FaTimes,
  FaRegUserCircle,
} from "react-icons/fa";
import {
  Input,
  Select,
  Button,
  InputNumber,
  Checkbox,
  Row,
  Col,
  Collapse as AntdCollapse,
  Radio,
} from "antd";
import { Modal } from "react-bootstrap";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllMedicinesAPI,
  getAllTestsAPI,
  getPrescriptionDataAPI,
} from "../../redux/slices/prescription/prescriptionService";
import { toast } from "react-toastify";
import {
  addDocPrescriptionAPI,
  getPrescriptionData,
} from "../../redux/slices/doctor/doctorService";
import { getBookingDetailsAPI } from "../../redux/slices/dashboard/dashboardService";
import { htmlTemplate, transformText } from "../../Scenes/common";
import SearchBar from "./SearchBar";
import { getUniversalSearchData } from "../../redux/slices/generic/genericService";
import PrescriptionDocument from "./PrescriptionDocument";

const { Option } = Select;
const Collapse: any = AntdCollapse;
const { Panel }: { Panel: any } = Collapse;

const Prescription = (props: any) => {
  const { id: prescriptionId } = props.match?.params;
  const [issue, setIssue] = useState<any>("");
  const [medicine, setMedicine] = useState<any>("");
  const [test, setTest] = useState("");
  const [issues, setIssues] = useState<any>([]);
  const [dose, setDose] = useState("");
  const [noOfDays, setNoOfDays] = useState(1);
  const [frequency, setFrequency] = useState({
    morning: 0,
    afternoon: 0,
    night: 0,
  });
  const [intake, setIntake] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [note, setNote] = useState("");
  const [showFrequencyModal, setShowFrequencyModal] = useState(false);
  const [diagnosis, setDiagnosis] = useState("");
  const [symptoms, setSymptoms] = useState("");
  const [nextVisit, setNextVisit] = useState(null);
  const [testNote, setTestNote] = useState("");
  const dispatch = useDispatch();
  const [allMedicines, setAllMedicines] = useState<any>();
  const [alltests, setAllTests] = useState<any>();
  const [selectedMedicines, setSelectedMedicines] = useState<any[]>([]);
  const [selectedTests, setSelectedTests] = useState<any[]>([]);
  const [currentBooking, setCurrentBooking] = useState<any>();
  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [previewHtml, setPreviewHtml] = useState("");
  const [errorMessage, setErrorMessage] = useState("This is an Error Message");
  const { universalSearchResults } = useSelector(({ generic }: any) => generic);
  const [medicineType, setMedicineType] = useState("branded"); // Default value is 'branded'

  const handleTypeChange = (e: any) => {
    setMedicineType(e.target.value);
  };
  useEffect(() => {
    const getBkUserDetails = async () => {
      const res = (await dispatch(getBookingDetailsAPI(prescriptionId))) as any;
      setCurrentBooking(res?.payload.data.bookingById);
      console.log("setCurrentBooking : ", res?.payload.data.bookingById);

      if (res?.error) {
        toast.error(res?.error?.message || "Unknown Error Occured");
        return;
      }
    };
    getBkUserDetails();
  }, [prescriptionId]);

  useEffect(() => {
    const getAllMedicines = async () => {
      const res = (await dispatch(getAllMedicinesAPI())) as any;

      if (res?.error) {
        toast.error(res?.error?.message || "Unknown Error Occured");
      }
      if (res?.payload?.data?.medicines) {
        console.log("getAllMedicines : ", res?.payload?.data?.medicines);
        setAllMedicines(res?.payload?.data?.medicines);
      }
    };
    getAllMedicines();
  }, []);

  useEffect(() => {
    const getPrescription = async () => {
      const res = (await dispatch(getPrescriptionData(prescriptionId))) as any;
      if (res?.error) {
        toast.error("Failed to fetch prescription data.");
        return;
      }

      const data = res?.payload?.data;

      if (data) {
        // Set symptoms as issues list
        console.log("data : ", data);

        setIssues(data.symptoms ? data.symptoms.split(", ") : []);
        setNote(data?.note || "");

        // Set selected medicines
        setSelectedMedicines(
          data.prescriptions_medicines.map((med: any) => ({
            service_name: med?.medicine_id, // Assuming medicine_id is the name or adjust accordingly
            dose: med?.dosage,
            noOfDays: med?.no_of_days,
            medicine_id: med?.medicine_id,
            frequency: {
              morning: parseInt(med?.frequency.split("-")[0], 10),
              afternoon: parseInt(med?.frequency.split("-")[1], 10),
              night: parseInt(med?.frequency.split("-")[2], 10),
            },
            intake: med?.intake,
            startDate: dayjs(med?.start_date).toDate(),
            note: med?.note,
            price: {
              discounted_price: med?.price?.discounted_price, // Adjust if price data is available in response
            },
          }))
        );

        setSelectedTests(
          [...data?.diagnostic_tests, ...data?.radiology_tests].map(
            (test: any) => ({
              service_name: test?.test_id, // Assuming test_id is the name or adjust accordingly
              diagnosis: test?.diagnosis,
              symptoms: test?.symptoms,
              test_id: test?.test_id,
              nextVisit: test?.next_visit
                ? dayjs(test?.next_visit).toDate()
                : null,
              testNote: test?.note,
              type: test?.type,
              price: {
                discounted_price: test?.price?.discounted_price, // Adjust if price data is available in response
              },
            })
          )
        );
      }
    };

    getPrescription();
  }, [prescriptionId, dispatch]);

  useEffect(() => {
    const getAllTests = async () => {
      const res = (await dispatch(getAllTestsAPI())) as any;
      if (res?.error) {
        toast.error(res?.error?.message || "Unknown Error Occured");
      }
      if (res?.payload?.data?.tests) {
        setAllTests(res?.payload?.data?.tests);
      }
    };
    getAllTests();
  }, []);

  const handleAddMedicine = () => {
    const selectedMedicine = universalSearchResults.find(
      (med: any) => med?.linkable_id === medicine
    );
    if (selectedMedicine) {
      const newMedicine = {
        ...selectedMedicine,
        dose,
        noOfDays,
        frequency,
        intake,
        startDate,
        // note,
      };

      if (dose === "" || intake === "") {
        return;
      }

      setSelectedMedicines([...selectedMedicines, newMedicine]);
      setMedicine("");
      setDose("");
      setNoOfDays(1);
      setFrequency({ morning: 0, afternoon: 0, night: 0 });
      setIntake("");
      setStartDate(new Date());
      // setNote("");
    }
  };

  const handleAddTest = () => {
    const selectedTest = alltests.find(
      (testItem: any) => testItem.service_name === test
    );
    if (selectedTest) {
      const newTest = {
        ...selectedTest,
        diagnosis,
        symptoms,
        nextVisit,
        // testNote,
      };

      if (diagnosis === "" || symptoms === "" || !nextVisit) {
        return;
      }
      setSelectedTests([...selectedTests, newTest]);
      setTest("");
      setDiagnosis("");
      setSymptoms("");
      setNextVisit(null);
      // setTestNote("");
    }
  };

  const renderMedicineRows = () => {
    return selectedMedicines.map((medicine, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{medicine?.service_name || medicine?.name}</td>
        <td>{medicine?.dose}</td>
        <td>{medicine?.noOfDays}</td>
        <td>{`${medicine?.frequency?.morning}-${medicine?.frequency?.afternoon}-${medicine?.frequency?.night}`}</td>
        <td>{medicine?.intake}</td>
        <td>{medicine?.price?.discounted_price || "N/A"}</td>
      </tr>
    ));
  };

  const renderTestRows = (type: string) => {
    return selectedTests
      .filter((test) => test?.type === type)
      .map((test, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{test?.service_name}</td>
          <td>{test?.diagnosis}</td>
          <td>{test?.symptoms}</td>
          <td>{test?.price?.discounted_price}</td>
          <td>
            {test?.nextVisit ? dayjs(test?.nextVisit).format("DD-MM-YYYY") : ""}
          </td>
        </tr>
      ));
  };

  const handleSelectSymptom = (symptom: any) => {
    console.log("symptom : ", symptom);

    if (symptom) {
      setIssues([...issues, symptom.trim()]);
      setIssue("");
    }
  };

  const handleDeleteIssue = (index: any) => {
    setIssues(issues.filter((_: any, i: any) => i !== index));
  };

  const handleFrequencyChange = (timeOfDay: any, value: any) => {
    try {
      setFrequency({ ...frequency, [timeOfDay]: value });
    } catch (error) {
      console.error("Error updating frequency:", error);
    }
  };

  const handlePrescriptionBooking = async () => {
    const prescriptionBody = {
      booking_id: prescriptionId,
      symptoms: issues?.join(", "), // Convert issues array to a comma-separated string
      note,
      prescriptions_medicines: selectedMedicines?.map((med) => ({
        medicine_id: med?.service_code || med?.linkable_id || med?.medicine_id,
        count: med?.noOfDays, // Assuming count refers to the number of days
        dosage: med?.dose,
        no_of_days: med?.noOfDays,
        frequency: `${med?.frequency.morning}-${med?.frequency.afternoon}-${med?.frequency.night}`,
        intake: med?.intake,
        start_date: new Date(med?.startDate).toISOString().split("T")[0], // Format date to YYYY-MM-DD
      })),
      prescriptions_tests: selectedTests?.map((test) => ({
        test_id: test?.service_code || test?.test_id,
        diagnosis: test?.diagnosis,
        symptoms: test?.symptoms,
        next_visit: new Date(test?.nextVisit)?.toISOString().split("T")[0], // Format date to YYYY-MM-DD
        type: test?.type,
      })),
    };

    const res = (await dispatch(
      addDocPrescriptionAPI(prescriptionBody)
    )) as any;
    if (res?.error) {
      toast.error(res?.error?.message || "Unknown Error Occured");
      return false;
    } else {
      toast.success("Prescription Added Succesfully");
      return true;
    }
  };

  const handlePrescriptionPreview = async () => {
    console.log("id : ", prescriptionId);
    if (!prescriptionId) {
      toast.error("Prescription ID Not found");
      return;
    }
    const resPresc = (await handlePrescriptionBooking()) as any;
    if (!resPresc) {
      return;
    }

    const res = (await dispatch(getPrescriptionDataAPI(prescriptionId))) as any;
    const data = res?.payload;
    setPreviewData(data);
    const prescHtml = await htmlTemplate(data); // Generate HTML
    // renderPrescHtmlOnScreen(prescHtml);
    setPreviewHtml(prescHtml);
    setShowPreviewModal(true);
  };

  const handleMedSearch = async (searchQuery: any) => {
    if (!searchQuery) {
      return;
    }
    const searchObj = {
      searchText: searchQuery,
      type: medicineType,
    };
    dispatch(getUniversalSearchData(searchObj));
  };

  useEffect(() => {
    console.log("universalSearchResults : ", universalSearchResults);
  }, [universalSearchResults]);

  return (
    <PrescriptionStyled>
      <div>
        <div className="prescription-container">
          <div className="profile-section">
            {currentBooking?.user?.image ? (
              <img
                src={currentBooking?.user?.image}
                alt=""
                className="userImg"
              />
            ) : (
              <FaRegUserCircle size={32} />
            )}
            <div className="user-info">
              <h2 className="user-name">
                {currentBooking?.user?.first_name}{" "}
                {currentBooking?.user?.last_name}
              </h2>
              <p className="user-details">
                Age: <span>{currentBooking?.user?.age}</span> Gender:{" "}
                <span>{currentBooking?.user?.gender}</span> City:{" "}
                <span>{currentBooking?.address?.city}</span>
              </p>
              <p className="purpose">
                Purpose: <span>{transformText(currentBooking?.type)}</span>
              </p>
            </div>
          </div>
          <div className="appointment-info">
            <p className="doctor-name">
              DR. {user?.first_name} {user?.last_name}
            </p>
            <p className="date">
              Date: <span>{new Date().toLocaleDateString("en-GB")}</span>
            </p>
            <p className="time">
              Time:{" "}
              <span>
                {new Date().toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            </p>
            <p className="mode">
              Mode: <span>🏠</span>
            </p>
          </div>
        </div>

        {/* Search and Issue Tags */}
        <div className="issue-container mt-4">
          <p className="note-label">
            Search Symptoms <span className="text-red">*</span>{" "}
          </p>
          <SearchBar handleSelectSymptom={handleSelectSymptom} />
          {issues?.length < 1 && (
            <p className="error-text">Please Add Your Symptoms</p>
          )}
          <div className="issues-list">
            {issues.map((item: any, index: any) => (
              <div className="issue-tag" key={index}>
                {item}
                <FaTimes
                  className="icon delete-icon"
                  onClick={() => handleDeleteIssue(index)}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="medicine-container">
          <p className="note-label">
            Search Medicines <span className="text-red">*</span>
          </p>
          {!medicine && <p className="error-text">Please Add Medicines</p>}
          <div className="d-flex flex-row justify-content-evenly">
            <p>Choose Medicine Type</p>
            <Radio.Group
              onChange={handleTypeChange}
              value={medicineType} // Controlled value
              style={{ display: "flex", gap: "10px" }}
            >
              <Radio value="branded">Branded</Radio>
              <Radio value="generic">Generic</Radio>
            </Radio.Group>
          </div>
          <div className="search-bar">
            <FaSearch className="icon search-icon" />
            <Select
              showSearch
              placeholder="Search for medicines"
              onChange={(value) => {
                console.log("val : ", value);
                setMedicine(value);
              }}
              onSearch={handleMedSearch} // Triggered on typing in the search box
              filterOption={false} // Disable default filtering
              style={{ width: "100%" }}
            >
              {universalSearchResults.map((item: any) => (
                <Option key={item.id} value={item.linkable_id}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      src={item.image}
                      alt={item.name}
                      style={{
                        width: "30px",
                        height: "30px",
                        objectFit: "cover",
                      }}
                    />
                    <span>{item.name}</span>
                  </div>
                </Option>
              ))}
            </Select>

            {/* <FaMicrophone className="icon mic-icon" /> */}
          </div>
          <div className="prescription-inputs">
            <Row
              gutter={16}
              className="align-items-center justify-content-evenly"
            >
              <Col span={4} className="h-fit">
                <p className="text-label">
                  Dosage <span className="text-red">*</span>
                </p>
                <Input
                  placeholder="Add Dose"
                  value={dose}
                  onChange={(e) => setDose(e.target.value)}
                />
              </Col>
              <Col span={4} className="h-fit">
                <p className="text-label">No Of Days</p>
                <InputNumber
                  min={1}
                  placeholder="No of days"
                  value={noOfDays}
                  onChange={(value) => setNoOfDays(value || 1)}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={4} className="h-fit">
                <p className="text-label">Frequency</p>
                <Button
                  type="default"
                  onClick={() => setShowFrequencyModal(true)}
                >
                  Frequency
                </Button>
              </Col>
              <Col span={4} className="h-fit">
                <p className="text-label">
                  Intake <span className="text-red">*</span>
                </p>
                <Select
                  placeholder="Intake"
                  value={intake || "Intake"}
                  showSearch
                  onChange={setIntake}
                  style={{ width: "100%" }}
                >
                  <Option value="after food">After Food</Option>
                  <Option value="before food">Before Food</Option>
                </Select>
              </Col>
              <Col span={4} className="h-fit">
                <p className="text-label">Start Date</p>
                <DatePicker
                  selected={startDate}
                  onChange={(date: any) => setStartDate(date)}
                  minDate={new Date()} // Disable dates before today
                  placeholderText="Start Date"
                  dateFormat="dd-MM-yyyy"
                  className="custom-datepicker"
                />
              </Col>
            </Row>
          </div>
          <div className="note-section">
            {medicine && !dose && (
              <p className="error-text">Please Add Dosage</p>
            )}
            {medicine && !intake && (
              <p className="error-text">Please Select Intake</p>
            )}

            {/* <p className="note-label">
              Note <span className="text-red">*</span>
            </p>
            <Input.TextArea
              placeholder="Add any additional notes here..."
              value={note}
              onChange={(e) => setNote(e.target.value)}
              rows={3}
            /> */}
          </div>
          <Button
            type="primary"
            className="add-button"
            style={{ marginTop: "8px" }}
            onClick={handleAddMedicine}
          >
            Add
          </Button>{" "}
        </div>

        <div className="tests-container">
          <p className="note-label">Pick a Test / Scan</p>
          {!test && <p className="error-text">Please Add Tests or Scans</p>}

          <Row gutter={16} className="align-items-center">
            <Col span={6}>
              <p className="text-label">
                Test / Scan <span className="text-red">*</span>
              </p>

              <Select
                showSearch
                placeholder="Pick a Test / Scan"
                value={test || "Pick a Test / Scan"}
                onChange={(value) => {
                  setTest(value);
                }}
                style={{ width: "100%" }}
              >
                {alltests?.map((testItem: any) => (
                  <Option
                    key={testItem.service_code}
                    value={testItem.service_name}
                  >
                    {testItem.service_name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={6}>
              <p className="text-label">
                Diagnosis <span className="text-red">*</span>
              </p>
              <Input
                placeholder="Diagnosis"
                value={diagnosis}
                onChange={(e) => setDiagnosis(e.target.value)}
              />
            </Col>
            <Col span={6}>
              <p className="text-label">
                Symptoms <span className="text-red">*</span>
              </p>
              <Input
                placeholder="Symptoms"
                value={symptoms}
                onChange={(e) => setSymptoms(e.target.value)}
              />
            </Col>
            <Col span={6}>
              <p className="text-label">
                Next Visit <span className="text-red">*</span>
              </p>
              <DatePicker
                selected={nextVisit}
                onChange={(date: any) => setNextVisit(date)}
                minDate={new Date()} // Disable dates before today
                placeholderText="Next Visit"
                dateFormat="dd-MM-yyyy"
                className="custom-datepicker"
              />
            </Col>
          </Row>

          {/* Note and Add Button */}
          <div style={{ marginTop: "16px" }}>
            {test && !diagnosis && (
              <p className="error-text">Please Add Diagnosis</p>
            )}
            {test && !symptoms && (
              <p className="error-text">Please Add the Symptom</p>
            )}
            {test && !nextVisit && (
              <p className="error-text">Please Select the next visit date</p>
            )}

            {/* <p className="note-label">
              Note <span className="text-red">*</span>
            </p>
            <Input.TextArea
              placeholder="Add any additional notes for tests..."
              value={testNote}
              onChange={(e) => setTestNote(e.target.value)}
              rows={3}
            /> */}
            <Button
              type="primary"
              className="add-button"
              style={{ marginTop: "8px" }}
              onClick={handleAddTest}
            >
              Add
            </Button>
          </div>
        </div>

        <p className="note-label">
          Note <span className="text-red">*</span>
        </p>
        <Input.TextArea
          placeholder="Add any additional notes here..."
          value={note}
          onChange={(e) => setNote(e.target.value)}
          rows={3}
        />

        <div className="button-container">
          <button
            className="preview-button"
            onClick={handlePrescriptionPreview}
          >
            Preview
          </button>
          <button className="book-button" onClick={handlePrescriptionBooking}>
            Submit
          </button>
        </div>
      </div>

      <div className="accordion-container">
        {/* {errorMessage && errorMessage !== "" && (
          <div
            className="errorMessage"
            style={{
            
            }}
          >
            {errorMessage}
          </div>
        )} */}
        <Collapse defaultActiveKey={["1", "2", "3"]}>
          {selectedMedicines.length > 0 && (
            <Panel header={`Pharmacy (${selectedMedicines.length})`} key="1">
              <table>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                    <th>Dose</th>
                    <th>Days</th>
                    <th>Frequency</th>
                    <th>Intake</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>{renderMedicineRows()}</tbody>
              </table>
            </Panel>
          )}
          {selectedTests.filter((test) => test?.type === "diagnostic").length >
            0 && (
            <Panel
              header={`Lab Test (${
                selectedTests.filter((test) => test?.type === "diagnostic")
                  .length
              })`}
              key="2"
            >
              <table>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                    <th>Diagnosis</th>
                    <th>Symptoms</th>
                    <th>Price</th>
                    <th>Next Visit</th>
                  </tr>
                </thead>
                <tbody>{renderTestRows("diagnostic")}</tbody>
              </table>
            </Panel>
          )}
          {selectedTests.filter((test) => test?.type === "ctmri").length >
            0 && (
            <Panel
              header={`Radiology (${
                selectedTests.filter((test) => test?.type === "ctmri").length
              })`}
              key="3"
            >
              <table>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                    <th>Diagnosis</th>
                    <th>Symptoms</th>
                    <th>Price</th>
                    <th>Next Visit</th>
                  </tr>
                </thead>
                <tbody>{renderTestRows("ctmri")}</tbody>
              </table>
            </Panel>
          )}
        </Collapse>
        {note && (
          <div className="mt-4">
            <p>Prescription Notes</p>
            <Input.TextArea
              value={note}
              readOnly
              rows={3}
              style={{ width: "100%", border: "none" }}
            />
          </div>
        )}
        {issues && (
          <div className="mt-4">
            <p>Prescription Issues</p>
            <Input.TextArea
              value={issues}
              readOnly
              rows={3}
              style={{ width: "100%", border: "none" }}
            />
          </div>
        )}
      </div>
      {/* Frequency Modal */}
      <Modal
        show={showFrequencyModal}
        onHide={() => setShowFrequencyModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Set Frequency</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row gutter={8}>
            <Col span={8}>
              <Checkbox>Morning</Checkbox>
              <InputNumber
                min={0}
                value={frequency.morning}
                onChange={(value) =>
                  handleFrequencyChange("morning", value || 0)
                }
              />
            </Col>
            <Col span={8}>
              <Checkbox>Afternoon</Checkbox>
              <InputNumber
                min={0}
                value={frequency.afternoon}
                onChange={(value) =>
                  handleFrequencyChange("afternoon", value || 0)
                }
              />
            </Col>
            <Col span={8}>
              <Checkbox>Night</Checkbox>
              <InputNumber
                min={0}
                value={frequency.night}
                onChange={(value) => handleFrequencyChange("night", value || 0)}
              />
            </Col>
          </Row>
          <Row className="justify-content-end mt-4">
            <Button onClick={() => setShowFrequencyModal(false)}>Add</Button>
          </Row>
        </Modal.Body>
      </Modal>

      <PrescriptionDocument
        data={previewData}
        prescHtml={previewHtml}
        showPreviewModal={showPreviewModal}
        setShowPreviewModal={setShowPreviewModal}
      />
    </PrescriptionStyled>
  );
};

export default Prescription;
