import { AnyAction, createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get, patch, del } from "../../../library/Requests/helpers";

import {
  constructDoctorsListQuery,
  constructDoctorHospitalListQuery,
  constructHospitalListQuery,
  getDoctorByClinic,
} from "../../../Scenes/graphqlConfig";

export const getDoctorsListAPI = createAsyncThunk(
  "doctor/getDoctorsListAPI",
  async (obj: any) =>
    await post(`${SERVER_IP}/graphql`, constructDoctorsListQuery(obj))
);

export const getDoctorHospitalListAPI = createAsyncThunk(
  "doctor/getDoctorHospitalListAPI",
  async (id: string) =>
    await post(`${SERVER_IP}/graphql`, constructDoctorHospitalListQuery(id))
);

export const getHospitalListAPI = createAsyncThunk(
  "doctor/getHospitalListAPI",
  async (obj: any) =>
    await post(`${SERVER_IP}/graphql`, constructHospitalListQuery(obj))
);

export const doctorRegistration = createAsyncThunk(
  "doctor/doctorRegistration",
  async (docBody: any) => {
    const url = `${SERVER_IP}/api/v1/doctor/register-doctor${
      docBody?.parentId ? `?child_doctor_id=${docBody.parentId}` : ""
    }`;

    return await post(url, docBody?.body);
  }
);

export const getDoctorProfileDetails = createAsyncThunk(
  "doctor/getDoctorProfileDetails",
  async (id: string) => await get(`${SERVER_IP}/api/v1/doctor/${id}/details`)
);

export const doctorMedRegistration = createAsyncThunk(
  "doctor/doctorMedRegistration",
  async (body: any) =>
    await put(
      `${SERVER_IP}/api/v1/doctor/${body.id}/medicalregistration`,
      body.medObj
    )
);

export const doctorQualification = createAsyncThunk(
  "doctor/doctorQualification",
  async (body: any) =>
    await put(
      `${SERVER_IP}/api/v1/doctor/${body.id}/doctor-qualification`,
      body.qualificationObj
    )
);

export const doctorEstablishmentDetails = createAsyncThunk(
  "doctor/doctorEstablishmentDetails",
  async (body: any) =>
    await put(
      `${SERVER_IP}/api/v1/doctor/${body.id}/establishment-details`,
      body.establishmentObj
    )
);

export const doctorUserAddress = createAsyncThunk(
  "doctor/doctorUserAddress",
  async (body: any) =>
    await put(
      `${SERVER_IP}/api/v1/doctor/${body.id}/user-address`,
      body.userAddrObj
    )
);

export const getDoctorInfoAPI = createAsyncThunk(
  "doctor/getDoctorInfoAPI",
  async (phone: any) =>
    await get(`${SERVER_IP}/api/v1/doctor/doctor/by-phone/${phone}`)
);

export const doctorBankDetails = createAsyncThunk(
  "doctor/doctorBankDetails",
  async (bankObj: any) =>
    await post(`${SERVER_IP}/api/v1/doctor/addbank`, bankObj)
);

export const getDoctorSpecializationAPI = createAsyncThunk(
  "doctor/getDoctorSpecializationAPI",
  async () => await get(`${SERVER_IP}/api/v1/config/doctorspecializations`)
);

export const getDoctorOrdersAPI = createAsyncThunk(
  "doctor/getDoctorOrdersAPI",
  async () => await get(`${SERVER_IP}/api/v1/doctor/orders`)
);

export const getProfileDetails = createAsyncThunk(
  "doctor/getProfileDetails",
  async () => await get(`${SERVER_IP}/api/v1/doctor/me`)
);

export const instantConnectAPI = createAsyncThunk(
  "doctor/instantConnectAPI",
  async (body: any) =>
    await post(`${SERVER_IP}/api/v1/dashboard/callMasking`, body)
);

export const addClinicAPI = createAsyncThunk(
  "doctor/addClinicAPI",
  async (body: any) => await post(`${SERVER_IP}/api/v1/doctor/my-clinic`, body)
);

export const updateClinicAPI = createAsyncThunk(
  "doctor/updateClinicAPI",
  async (body: any) =>
    await put(
      `${SERVER_IP}/api/v1/doctor/${body.id}/myclinic-update`,
      body.myclinic
    )
);

export const updateConsultationFeeAPI = createAsyncThunk(
  "doctor/updateConsultationFeeAPI",
  async (body: any) =>
    await put(
      `${SERVER_IP}/api/v1/doctor/${body.id}/selfservicedetails`,
      body.consultationFeeObj
    )
);

export const updateProofUploadAPI = createAsyncThunk(
  "doctor/updateProofUploadAPI",
  async (body: any) =>
    await put(
      `${SERVER_IP}/api/v1/doctor/${body.id}/proof-upload`,
      body.proofObj
    )
);

export const deleteClinicAPI = createAsyncThunk(
  "doctor/deleteClinicAPI",
  async (id: string) => await del(`${SERVER_IP}/api/v1/doctor/clinic/${id}`)
);

export const getClinicByIdAPI = createAsyncThunk(
  "doctor/getClinicByIdAPI",
  async (id: string) => await get(`${SERVER_IP}/api/v1/doctor/clinic/${id}`)
);

export const getAllClinicsAPI = createAsyncThunk(
  "doctor/getAllClinicsAPI",
  async () => await get(`${SERVER_IP}/api/v1/doctor/clinics`)
);

export const getAllDoctorClinicsAPI = createAsyncThunk(
  "doctor/getAllDoctorClinicsAPI",
  async (doctorid: any) =>
    await get(`${SERVER_IP}/api/v1/doctor/clinics/${doctorid}`)
);

export const createTaskBoardAPI = createAsyncThunk(
  "doctor/createTaskBoardAPI",
  async (body: any) => await post(`${SERVER_IP}/api/v1/doctor/board`, body)
);

export const getAllTaskBoardsAPI = createAsyncThunk(
  "doctor/getAllTaskBoardsAPI",
  async (id: string) => await get(`${SERVER_IP}/api/v1/doctor/board/${id}`)
);

export const getAllTasksAPI = createAsyncThunk(
  "doctor/getAllTasksAPI",
  async (id: string) => await get(`${SERVER_IP}/api/v1/doctor/tasks/${id}`)
);

export const createTaskAPI = createAsyncThunk(
  "doctor/createTaskAPI",
  async (body: any) => await post(`${SERVER_IP}/api/v1/doctor/task`, body)
);

export const createCommentAPI = createAsyncThunk(
  "doctor/createCommentAPI",
  async (body: any) => await post(`${SERVER_IP}/api/v1/doctor/comment`, body)
);

export const updateTaskAPI = createAsyncThunk(
  "doctor/updateTaskAPI",
  async (taskObj: any) =>
    await put(`${SERVER_IP}/api/v1/doctor/task/${taskObj?.id}`, taskObj?.body)
);

export const createBlogAPI = createAsyncThunk(
  "doctor/createBlogAPI",
  async (body: any) => await post(`${SERVER_IP}/api/v1/doctor/blog`, body)
);

export const updateMapLocationAPI = createAsyncThunk(
  "doctor/updateMapLocationAPI",
  async (body: any) =>
    await put(`${SERVER_IP}/api/v1/doctor/${body.id}/map-location`, body.mapObj)
);

export const addBookingAPI = createAsyncThunk(
  "doctor/addBookingAPI",
  async (body: any) => await post(`${SERVER_IP}/api/v1/doctor/booking`, body)
);

export const addDocToClinicAPI = createAsyncThunk(
  "doctor/addDocToClinicAPI",
  async (body: any) =>
    await post(`${SERVER_IP}/api/v1/doctor/addDoctorToClinic`, body)
);

export const doctorByClinicAPI = createAsyncThunk(
  "auth/doctorByClinicAPI",
  async (body: any) =>
    await post(`${SERVER_IP}/graphql`, JSON.stringify(getDoctorByClinic(body)))
);

export const unlinkDocFromClinicAPI = createAsyncThunk(
  "auth/unlinkDocFromClinicAPI",
  async (id: any) => await post(`${SERVER_IP}/api/v1/doctor/removeDoc/${id}`)
);

export const getPrescriptionData = createAsyncThunk(
  "auth/getPrescriptionData",
  async (id: any) => await get(`${SERVER_IP}/api/v1/prescription/${id}`)
);

export const addDocPrescriptionAPI = createAsyncThunk(
  "auth/addDocPrescriptionAPI",
  async (body: any) => await post(`${SERVER_IP}/api/v1/prescription/add/`, body)
);

export const docAttendance = createAsyncThunk(
  "auth/docAttendance",
  async (id: any) => await get(`${SERVER_IP}/api/v1/doctor/attendance/${id}`)
);

export const addDocEmployeeAPI = createAsyncThunk(
  "auth/addDocEmployeeAPI",
  async (body: any) => await post(`${SERVER_IP}/api/v1/doctor/employee/`, body)
);

export const getAllDocEmployees = createAsyncThunk(
  "auth/getAllDocEmployees",
  async (id: any) => await get(`${SERVER_IP}/api/v1/doctor/employee/${id}`)
);

export const addDutyRoaster = createAsyncThunk(
  "auth/addDutyRoaster",
  async (body: any) =>
    await post(`${SERVER_IP}/api/v1/doctor/duty-roster/`, body)
);

export const addAttendance = createAsyncThunk(
  "auth/addAttendance",
  async (body: any) =>
    await post(`${SERVER_IP}/api/v1/doctor/attendance/`, body)
);

export const updateAttendance = createAsyncThunk(
  "auth/updateAttendance",
  async (body: any) =>
    await put(
      `${SERVER_IP}/api/v1/doctor/attendance/${body?.id}`,
      body?.payload
    )
);

export const downloadAttendance = createAsyncThunk(
  "auth/downloadAttendance",
  async (body: any) =>
    await get(
      `${SERVER_IP}/api/v1/doctor/attendance/${body?.id}?date=${body?.date}&format=csv`
    )
);
