import { Button } from "antd";
import React from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
const PrescriptionPreview = ({
  data,
  prescHtml,
  showPreviewModal,
  setShowPreviewModal,
}: any) => {
  const handleDownload = async () => {
    const htmlContent = prescHtml;
    try {
      toast.success("Download will begin Shortly");
      const response = await fetch(
        "https://test-api-h15z.onrender.com/generate-pdf",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ htmlContent }),
        }
      );

      if (!response.ok) {
        toast.error("Failed to generate PDF");
        return;
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Create a link element to download the PDF
      const link = document.createElement("a");
      link.href = url;
      link.download = "generated_prescription.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error("Error generating PDF");
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <Modal
      show={showPreviewModal}
      onHide={() => setShowPreviewModal(false)}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Prescription Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          id="preview-content"
          dangerouslySetInnerHTML={{ __html: prescHtml }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShowPreviewModal(false)}>Close</Button>
        <Button onClick={handleDownload}>Download</Button>
        <Button
          onClick={() => {
            const printWindow = window.open("", "_blank") as any;
            printWindow.document.write(`${prescHtml}`);
            printWindow.document.close();
            printWindow.focus();
            printWindow.print();
            printWindow.close();
          }}
        >
          Print
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PrescriptionPreview;
