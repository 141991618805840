import React, { useState } from "react";
import { Select } from "antd";
import { FaSearch } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { googleSearchAPI } from "../../redux/slices/prescription/prescriptionService";

const SearchBar = ({ handleSelectSymptom }: any) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const dispatch = useDispatch();

  const handleSearch = async (value: string) => {
    setSearchTerm(value);

    if (value.trim()) {
      try {
        const response = (await dispatch(googleSearchAPI(value))) as any;

        // Process the payload array into Select-compatible options
        const suggestions = response?.payload || [];
        const formattedOptions = suggestions.map((item: string) => ({
          value: item,
          label: item,
        }));

        // Always include the current search term as an option
        const searchTermOption = { value, label: value };
        const uniqueOptions = [
          //@ts-ignore
          ...new Set([
            ...formattedOptions.map((opt: any) => opt.value),
            searchTermOption.value,
          ]),
        ].map((val) => ({
          value: val,
          label: val,
        }));

        setOptions(uniqueOptions);
      } catch (error) {
        console.error("Error fetching search suggestions:", error);
        setOptions([{ value, label: value }]);
      }
    } else {
      setOptions([]);
    }
  };

  const handleSelect = (value: string) => {
    console.log("Selected value: ", value);
    handleSelectSymptom(value);
  };

  return (
    <div className="search-bar relative">
      <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 z-10 text-gray-400" />
      <Select
        showSearch
        value={searchTerm}
        placeholder="Search Issue"
        onSearch={handleSearch}
        onSelect={handleSelect} // Use onSelect instead of onChange
        options={options}
        style={{ width: "100%" }}
        className="pl-8" // Add left padding to account for the icon
        filterOption={false} // Disable internal filtering; use API results instead
        dropdownRender={(menu) => <div className="p-1">{menu}</div>}
      />
    </div>
  );
};

export default SearchBar;
