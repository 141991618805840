import React, { useEffect, useState } from "react";
import History from "./History";
import MarkAttendance from "./MarkAttendance";
import { useDispatch, useSelector } from "react-redux";
import { docAttendance } from "../../../../redux/slices/doctor/doctorService";
import { AttendanceStyled } from "./Attendance.styled";

const Attendance = () => {
  const [activeTab, setActiveTab] = useState("markAttendance");
  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const [doctorId, setDoctorId] = useState<any>();
  const [attendance, setAttendance] = useState<any>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.roles?.length > 0) {
      if (user.roles[0].linkable_id) {
        setDoctorId(user.roles[0].linkable_id);
      }
    }
  }, [user]);

  useEffect(() => {
    if (!doctorId) {
      return;
    }
    const getAllAttendance = async () => {
      const res = (await dispatch(docAttendance(doctorId))) as any;
      console.log("Res : ", res?.payload);
      setAttendance(res?.payload);
    };
    getAllAttendance();
  }, [doctorId]);

  return (
    <AttendanceStyled>
      <div className="attendance">
        <div className="attendance-header">
          <div className="Heading">Attendance</div>
          <div className="attendance-actions">
            <button
              className={`mark-attendance ${
                activeTab === "markAttendance" ? "active" : ""
              }`}
              onClick={() => setActiveTab("markAttendance")}
            >
              Mark Attendance
            </button>
            <button
              className={`recent-history ${
                activeTab === "recentHistory" ? "active" : ""
              }`}
              onClick={() => setActiveTab("recentHistory")}
            >
              Recent History
            </button>
          </div>
        </div>

        {activeTab === "markAttendance" && (
          <MarkAttendance doctorId={doctorId} attendance={attendance} />
        )}

        {activeTab === "recentHistory" && <History attendance={attendance} />}

        {/* <div className="exportbtn">
          <button>Export As Excel</button>
        </div> */}
      </div>
    </AttendanceStyled>
  );
};

export default Attendance;
