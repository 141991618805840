import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as Man } from "../../assets/icons/man.svg";
import { ReactComponent as Rep } from "../../assets/icons/rep.svg";
import { ReactComponent as Pres } from "../../assets/icons/pres.svg";
import { ReactComponent as Share } from "../../assets/icons/share.svg";
import { PatientDetStyled } from "./PateintsDet.styled";
import { useDispatch, useSelector } from "react-redux";
import { patientDetailAPI } from "../../redux/slices/myPatients/myPatientsService";
import { useHistory } from "react-router-dom";
import { PiPhoneCall } from "react-icons/pi";
import { instantConnectAPI } from "../../redux/slices/doctor/doctorService";
import { toast } from "react-toastify";
import {
  getPrescriptionAPI,
  getVirtualCallLinkAPI,
} from "../../redux/slices/bookingScreen/bookingScreenService";
import { getReportOrderAPI } from "../../redux/slices/generic/genericService";
import { FaCheckCircle } from "react-icons/fa";
import { BookingStyled } from "../../pages/Bookings/Bookings.styled";
import {
  getBookingStatusAPI,
  updateBookingStatusAPI,
} from "../../redux/slices/dashboard/dashboardService";
import { Select } from "antd";
import Loader from "../../components/Common/Loader";
import { transformText } from "../../Scenes/common";
const { Option } = Select;

const PatientDetailModal = ({
  show,
  onHide,
  patient,
  bookingId,
  bookingReport,
}: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [patientId, setPatientId] = useState(patient?.id);
  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const [showOrder, setShowOrder] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [orderItem, setOrderItem] = useState(bookingId);
  const [bookingLogs, setBookingLogs] = useState<any>([]);
  const [showLogs, setShowLogs] = useState(false);
  const [status, setStatus] = useState(patient?.status); // Initialize the status
  const [statuses, setStatuses] = useState<any[]>([]); // Statuses from the API
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setStatus(patient?.status);
  }, [patient]);

  useEffect(() => {
    console.log("statuses : ", statuses);
  }, [statuses]);

  const handleStatusChange = (newStatus: string) => {
    setStatus(newStatus);
  };

  // Rendering status buttons based on the current status
  const renderStatusButtons = () => {
    if (status === "open") {
      return (
        <>
          <button
            className="prescription-button"
            onClick={() => handleStatusChange("accepted")}
          >
            Accept <Pres />
          </button>
          <button
            className="prescription-button"
            onClick={() => handleStatusChange("rejected")}
          >
            Reject <Pres />
          </button>
          <button
            className="prescription-button"
            onClick={() => handleStatusChange("rescheduled")}
          >
            Reschedule <Pres />
          </button>
        </>
      );
    } else if (status === "accepted") {
      return (
        <>
          <button
            className="prescription-button"
            onClick={() => handleStatusChange("completed")}
          >
            Completed <Pres />
          </button>
          <button
            className="prescription-button"
            onClick={() => handleStatusChange("rescheduled")}
          >
            Reschedule <Pres />
          </button>
          <button
            className="prescription-button"
            onClick={() => handleStatusChange("canceled")}
          >
            Cancel <Pres />
          </button>
        </>
      );
    } else if (status === "rejected") {
      return (
        <>
          <button
            className="prescription-button"
            onClick={() => handleStatusChange("rescheduled")}
          >
            Reschedule <Pres />
          </button>
          <button
            className="prescription-button"
            onClick={() => handleStatusChange("canceled")}
          >
            Cancel <Pres />
          </button>
        </>
      );
    }
  };

  const toggleLogs = () => {
    setShowLogs((prevShowLogs) => !prevShowLogs);
  };

  const handleCloseOrder = () => setShowOrder(false);
  const handleCloseReport = () => setShowReport(false);
  const handleShowOrder = () => setShowOrder(true);
  const handleShowReport = () => setShowReport(true);

  function getFormattedDateFromTimestamp(timestamp: any) {
    try {
      if (isNaN(timestamp)) {
        throw new Error("Invalid timestamp: Not a number");
      }
      const date = new Date(parseInt(timestamp));
      if (date.toString() === "Invalid Date") {
        throw new Error("Invalid Date: The timestamp is not valid");
      }
      const day = date.toLocaleString("en-US", { weekday: "long" });
      const formattedDate = date.toLocaleString("en-US", {
        month: "numeric",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

      return `${formattedDate}, ${day}`;
    } catch (error: any) {
      console.error(error.message);
      return null;
    }
  }

  useEffect(() => {
    const fetchBookingLogs = async () => {
      if (!bookingId) {
        return;
      }
      const res = await dispatch(getReportOrderAPI(bookingId));
      const resJson = JSON.parse(JSON.stringify(res));
      setBookingLogs(resJson?.payload?.data);
    };
    setOrderItem(bookingId);
    fetchBookingLogs();
  }, [bookingId]);

  useEffect(() => {
    setPatientId(patient?.id);
    console.log("patient : ", patient);
  }, [patient]);

  const [patientDetails, setPatientDetails] = useState<any>();

  useEffect(() => {
    const getPatientInfo = async () => {
      if (patientId) {
        const res = await dispatch(patientDetailAPI(patientId));
        const resJson = JSON.parse(JSON.stringify(res));
        console.log(resJson?.payload?.data?.patientById, "resJson");
        setPatientDetails(resJson?.payload?.data?.patientById);
      } else {
        setPatientDetails(null);
      }
    };
    getPatientInfo();
  }, [patientId]);

  function capitalizeFirstLetter(word: string = "") {
    return word?.charAt(0)?.toUpperCase() + word?.slice(1);
  }

  const formatDate = (dateString: string) => {
    const [day, month, year] = dateString.split("-");
    const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    return date.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  const instantConnect = async () => {
    console.log(user, "user");

    const docId = `${user?.id}`;
    const userPhone = patient?.phone;
    if (!docId) {
      toast.error("Doctor Id Not Available");
      return;
    }
    if (!userPhone) {
      toast.error("Please set your phone number in the dashboard");
      return;
    }

    const body = {
      doctorId: docId,
      phone: userPhone,
    };
    const res = await dispatch(instantConnectAPI(body));
    const resJson = JSON.parse(JSON.stringify(res));
    console.log("res : ", resJson);
    if (resJson.error) {
      toast.error(resJson?.error?.message);
    }
    if (resJson.payload.data.callResponse === "OK") {
      toast.success("Connected to Doctor");
    }
  };

  const handlePrescriptionClick = async () => {
    history.push(`/prescription/${patient?.bkId}`);
    // setShowLoader(true);
    // const res = (await dispatch(getPrescriptionAPI(patient?.bkId))) as any;
    // console.log("res : ", res);
    // if (res?.error) {
    //   toast.error(res?.error?.message);
    //   setShowLoader(false);
    //   return;
    // } else {
    //   setShowLoader(false);
    // }
  };

  const createVirtualCall = async (item: any) => {
    console.log(item, "item");
    if (!item.bkId) {
      toast.error("Booking Id Not Available");
      return;
    }
    const res = await dispatch(getVirtualCallLinkAPI(item?.bkId));
    const resJson = JSON.parse(JSON.stringify(res));
    if (resJson?.payload?.data?.link) {
      console.log("meet link", resJson.payload.data.link);
      window.open(resJson.payload.data.link, "_blank");
    }
  };

  useEffect(() => {
    const fetchStatuses = async () => {
      const res = await dispatch(getBookingStatusAPI());
      const resJson = JSON.parse(JSON.stringify(res));
      setStatuses(resJson?.payload?.data?.bookingStatuses);
    };
    fetchStatuses();
  }, [dispatch]);

  const handleStatusSelect = (statusId: string) => {
    setSelectedStatus(statusId); // only set the selected status
  };

  const saveStatusChange = () => {
    if (selectedStatus) {
      handleBkStatusChange(selectedStatus); // call handleBkStatusChange only on save button click
    } else {
      toast.error("Please select a status first");
    }
  };

  // Handle status selection
  const handleBkStatusChange = async (statusId: string) => {
    const selected = statuses.find((status) => status.id === statusId);
    setSelectedStatus(statusId);
    console.log("Selected status item:", selected);
    const updateObj = {
      id: patient?.bkId,
      bookingObj: {
        status: selected?.id,
      },
    };
    const res = await dispatch(updateBookingStatusAPI(updateObj));
    const resJson = JSON.parse(JSON.stringify(res));
    if (resJson?.error) {
      toast.error(resJson?.error?.message);
      return;
    } else {
      toast.success("Status updated successfully");
      handleStatusChange(selected?.id);
    }
  };

  const handleDownload = () => {
    const imageUrl = patient?.attachments?.url ? patient?.attachments?.url : "";
    if (imageUrl) {
      window.open(imageUrl, "_blank");
    } else {
      console.error("No image URL available");
    }
  };

  return (
    <>
      {showLoader && <Loader />}
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <div style={{ overflowX: "auto", height: "25rem" }}>
          <Modal.Body>
            <PatientDetStyled>
              <div className="profile-card chk1">
                <div className="profile-info">
                  <Man />
                  <div className="profile-details">
                    <h2>
                      {patient?.first_name || patient?.name}{" "}
                      {patient?.last_name}
                    </h2>
                    <p>
                      Age: <span>{patient?.age}</span> Gender:{" "}
                      <span>
                        {capitalizeFirstLetter(patientDetails?.gender)}
                      </span>
                      City:{" "}
                      <span>
                        {capitalizeFirstLetter(patientDetails?.address?.city)}
                      </span>
                    </p>
                    <p>
                      Phone: <span>{patient?.phone || patient?.mobilenum}</span> Email:{" "}
                      <span>{patient?.user?.email || patient?.email}</span>
                    </p>
                  </div>
                </div>
                <div className="d-flex gap-3 align-items-center">
                  {/* <button className="contact-button" onClick={handleShowOrder}>
                    Order History
                  </button> */}
                  {patient?.status === "booking_scheduled" && (
                    <button
                      className="contact-button"
                      onClick={() => {
                        window.open(
                          `/VideoCall?roomID=${patient?.bkId}`,
                          "_blank"
                        );
                      }}
                    >
                      Video Call
                    </button>
                  )}
                  {/* <button className="contact-button" onClick={instantConnect}>
                    Audio Call
                  </button> */}
                  {/* <div className="d-flex flex-column gap-2">
                    {renderStatusButtons()}
                  </div> */}
                </div>
              </div>

              <div className="d-flex justify-content-evenly">
                {/* Searchable Select Dropdown */}
                {patient?.bkId && (
                  <>
                    <Select
                      showSearch
                      placeholder="Select a status"
                      style={{ width: "200px" }} // You can adjust the width here
                      optionFilterProp="children"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      } // Ensure it's relative to the parent
                      // onChange={handleBkStatusChange}
                      onSelect={handleStatusSelect}
                      filterOption={(input: any, option: any) =>
                        option?.children
                          ?.toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {statuses.map((status) => (
                        <Option key={status.id} value={status.id}>
                          {status.name}
                        </Option>
                      ))}
                    </Select>
                    <button
                      className="prescription-button"
                      onClick={handlePrescriptionClick}
                    >
                      Prescription <Pres />
                    </button>
                    <button
                      className="save-button prescription-button"
                      onClick={saveStatusChange}
                    >
                      Save
                    </button>
                  </>
                )}

                {patient?.status === "completed" && (
                  <button className="prescription-button">
                    Report <Pres />
                  </button>
                )}
              </div>

              <div className="appointment-details">
                <div className="header d-flex flex-column">
                  <h2>Appointment Details</h2>
                  <div className="header-right">
                    <div className="appointment-mode">
                      <span>Status :</span>{" "}
                      <span className="mode">{transformText(status)}</span>
                    </div>
                    <div className="appointment-actions">
                      {patient?.type !== "virtual_consultation" &&
                        patient?.status === "completed" && (
                          <button
                            className="report-button"
                            onClick={handleShowReport}
                          >
                            Report <Rep />
                          </button>
                        )}
                    </div>
                  </div>
                </div>
                <div className="appointment-info">
                  <div className="appointment-item">
                    <label>Date:</label>
                    <span>
                      {patient?.collection_1_date
                        ? getFormattedDateFromTimestamp(
                            patient.collection_1_date
                          )
                        : "N/A"}
                    </span>
                  </div>
                  <div className="appointment-item">
                    <label>Time:</label>
                    <span>{patient?.collection_1_slot || "N/A"}</span>
                  </div>
                  <div className="appointment-item">
                    <label>Purpose:</label>
                    <span>
                      {transformText(patient?.type) || "Fever & Cough"}
                    </span>
                  </div>
                  <div className="appointment-item">
                    <label>Client:</label>
                    <span>{patient?.client?.name || "N/A"}</span>
                  </div>
                  <div className="">
                    <label>Comments:</label>
                    {bookingLogs?.communicationLog?.map(
                      (item: any, index: number) => {
                        return <span>{patient?.client?.name || "N/A"}</span>;
                      }
                    )}

                    <div className="appointment-comments-details">
                      <div className="appointment-comment-info">
                        {bookingLogs?.bookingComments?.length > 0 ? (
                          bookingLogs?.bookingComments?.map(
                            (item: any, index: number) => {
                              return (
                                <div
                                  className="appointment-comment-item"
                                  key={index}
                                >
                                  <label>{item?.comment}</label>

                                  <li className="appointment-comment-role">
                                    {`${item?.user?.first_name} ${item?.user?.last_name} `}
                                    (
                                    {item?.user?.roles?.map(
                                      (roleObj: any, roleIndex: number) => (
                                        <span
                                          key={roleIndex}
                                          className="appointment-comment-role"
                                        >
                                          {roleObj?.role}
                                          {roleIndex <
                                          item.user.roles.length - 1
                                            ? ", "
                                            : ""}
                                        </span>
                                      )
                                    )}
                                    )
                                  </li>
                                </div>
                              );
                            }
                          )
                        ) : (
                          <span>N/A</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div></div>
                <div className="summary">
                  {patient?.notes && (
                    <p>
                      <h4>Summary</h4>
                      <p>{patient?.notes}</p>
                    </p>
                  )}
                </div>
              </div>
            </PatientDetStyled>
          </Modal.Body>
        </div>
      </Modal>
      <Modal show={showOrder} onHide={handleCloseOrder}>
        <Modal.Header closeButton></Modal.Header>
        <PatientDetStyled>
          <div>
            <div className="appointment-details">
              <div className="header">
                <h2>Order ID: {bookingId}</h2>
                <div className="header-right">
                  <div className="appointment-mode">
                    <span>Status :</span> <span className="mode">{status}</span>
                  </div>
                </div>
              </div>
              <div className="appointment-info mt-4">
                <div className="appointment-report-item">
                  <label>Order Placed on:</label>
                  <span>
                    {patient?.collection_1_date
                      ? getFormattedDateFromTimestamp(patient.collection_1_date)
                      : "N/A"}
                  </span>
                </div>
                <div className="appointment-report-item">
                  <span>
                    {bookingLogs?.bookingLogs?.length > 0 ? (
                      bookingLogs?.bookingLogs?.map(
                        (item: any, index: number) => (
                          <div key={index}>
                            <FaCheckCircle
                              color="#45A834"
                              className="icon me-2"
                            />
                            <span className="mb-0 ml-2">{item}</span>
                          </div>
                        )
                      )
                    ) : (
                      <span>N/A</span>
                    )}
                  </span>
                </div>
                <div className="appointment-report-item">
                  <label>Order Date:</label>

                  {bookingLogs?.bookingComments?.map(
                    (item: any, index: number) => {
                      return (
                        <span>
                          {patient?.collection_1_date
                            ? getFormattedDateFromTimestamp(
                                patient?.collection_1_date
                              )
                            : "N/A"}
                        </span>
                      );
                    }
                  )}
                </div>
                <div className="appointment-report-item">
                  <label onClick={toggleLogs} style={{ cursor: "pointer" }}>
                    {`Communication Logs (${bookingLogs?.communicationLogs?.length}) : `}
                  </label>

                  {showLogs && (
                    <div>
                      {bookingLogs?.communicationLogs?.length > 0 ? (
                        bookingLogs.communicationLogs.map(
                          (item: any, index: number) => (
                            <div key={index}>
                              <FaCheckCircle
                                color="#45A834"
                                className="icon me-2"
                              />
                              <span className="mb-0 ml-2">
                                email sent to {item?.to} for {item?.template_id}{" "}
                                at {item?.created_at}
                              </span>
                            </div>
                          )
                        )
                      ) : (
                        <p>N/A</p>
                      )}
                    </div>
                  )}
                </div>
                <div className="appointment-report-item">
                  <label>Payment Mode:</label>
                  <span>{"Offline"}</span>
                </div>
                <div className="appointment-report-item">
                  <label>Customer</label>
                  <br />
                  <label>Bill To :</label>
                  <span>
                    {patient?.first_name || patient?.name} {patient?.last_name}
                    {patient?.email} {patient?.phone}
                  </span>{" "}
                  <br />
                  <label>Relation :</label>
                  <span>{patient?.Relation || "N/A"}</span>
                  <br />
                  <label>Gender :</label>
                  <span>{patient?.gender || "N/A"}</span>
                  <br />
                  <label>Age :</label>
                  <span>{patient?.age || "N/A"}</span>
                </div>
                <div className="appointment-report-item">
                  <label>Client :</label>
                  <span>{patient?.client?.name || "N/A"}</span>
                </div>
                <div className="appointment-report-item">
                  <label>Address :</label>
                  <span>{patient?.address || "N/A"}</span>
                </div>
              </div>
            </div>
          </div>
        </PatientDetStyled>
      </Modal>
      <Modal show={showReport} onHide={handleCloseReport}>
        <Modal.Header closeButton>
          <h5>Report's</h5>
        </Modal.Header>
        <BookingStyled>
          <div className="report-imagei-div">
            <img
              src={patient?.attachments?.url ? patient?.attachments?.url : ""}
              alt="Document"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
          <div className=" report-imagei-div">
            <button className="btn" onClick={handleDownload}>
              Download Image
            </button>
          </div>
        </BookingStyled>
      </Modal>
    </>
  );
};

export default PatientDetailModal;
