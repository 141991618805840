import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { ReactComponent as Filter } from "../../../../assets/icons/filter.svg";
import { ReactComponent as Calendaricon } from "../../../../assets/icons/calendar.svg";
import Modal from "react-bootstrap/Modal";
import Calendar from "react-calendar";
import { ReactComponent as Present } from "../../../../assets/icons/present.svg";
import { AttendanceStyled } from "./Attendance.styled";

const History = ({ attendance }: any) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  // Filtered attendance data based on search term
  const filteredAttendance = attendance?.filter(
    (item: any) =>
      item?.employee_name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      item?.role?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  useEffect(() => {
    console.log("attendance : ", attendance);
  }, [attendance]);

  return (
    <AttendanceStyled>
      <div className="history-list">
        <div className="searchbarfilter">
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search Name or Role"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          {/* <div className="calendar">
            <Calendaricon onClick={toggleModal} />
            <Modal
              show={showModal}
              onHide={toggleModal}
              centered
              className="custom-modal"
            >
              <Modal.Header closeButton />
              <Modal.Body>
                <Calendar onChange={(date) => console.log(date)} />
              </Modal.Body>
            </Modal>
          </div> */}
        </div>
        <div className="recent-history-list">
          <Table responsive="sm">
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Role</th>
                <th>Status</th>
                <th>Check In</th>
                <th>Check Out</th>
                <th>Over Time</th>
              </tr>
            </thead>
            <tbody>
              {filteredAttendance.length > 0 ? (
                filteredAttendance.map((item: any, index: any) => (
                  <tr key={index}>
                    <td>{item.date || "N/A"}</td>
                    <td>{item.employee_name || "N/A"}</td>
                    <td>{item.role || "N/A"}</td>
                    <td>
                      <Present />{" "}
                      {item.status.charAt(0).toUpperCase() +
                        item.status.slice(1) || "N/A"}
                    </td>
                    <td>
                      {item.checkin_time
                        ? item.checkin_time.slice(0, 5)
                        : "N/A"}
                    </td>
                    <td>
                      {item.checkout_time
                        ? item.checkout_time.slice(0, 5)
                        : "N/A"}
                    </td>
                    <td>{item.overtime ? item.overtime.slice(0, 5) : "N/A"}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} className="text-center">
                    No attendance records found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </AttendanceStyled>
  );
};

export default History;
