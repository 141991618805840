import styled from "styled-components";

export const AttendanceStyled = styled.div`
  .attendance {
    padding-top: 30px;
  }

  .attendance-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Heading {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .title {
    font-size: 1.2em;
  }

  .attendance-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 20px;
  }

  .mark-attendance,
  .recent-history {
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid grey;
    background: #fff;
    color: grey;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .mark-attendance.active,
  .recent-history.active {
    border: 1px solid #9747ff;
    color: #9747ff;
  }

  .attendance-list {
    display: flex;
    flex-direction: column;
    background-color: #fff;
  }

  .attendance-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    gap: 6px;
  }

  .name {
    color: #5b5050;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .check-in,
  .check-out,
  .over-time {
    padding: 9px 30px;
    cursor: pointer;
    border-radius: 3px;
    border: 1px solid #c9b2b2;
    background: #fff;
    color: #00ae84;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 5px;
  }

  .savebtn {
    text-align: end;
  }

  .save {
    color: #9747ff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 86px;
    padding: 6px;
    background: #fff;
    border: 1px solid #9747ff;
    border-radius: 5px;
  }

  .mark-leave {
    display: flex;
    align-items: center;
    color: #6b5353;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .mark-leave input {
    margin-right: 5px;
  }

  .list-heading {
    display: flex;
    border-radius: 3px 3px 0px 0px;
    background: #d3edfc;
    padding: 6px;
    margin: 2px;
    align-items: center;
  }

  .Date {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 16px;
  }

  .listhead {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .exportbtn {
    text-align: end;
  }

  .exportbtn button {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
    border-radius: 5px;
    border: 1px solid #f5e8e8;
    background: #fff;
    padding: 6px;
    margin: 16px 0;
  }

  .recent-history-list table {
    border-radius: 10px;
    border: 1px solid #ccc;
  }

  .recent-history-list table th {
    background-color: #d3edfc;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .recent-history-list table td {
    color: #605454;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background-color: transparent;
  }

  .searchbarfilter {
    display: flex;
    margin: 20px 0;
  }
  @media (max-width: 768px) {
    .attendance-row {
      grid-template-columns: 1fr 1fr;
    }
  }
  .reports-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    font-family: Arial, sans-serif;
  }

  .reports-table th,
  .reports-table td {
    padding: 12px 15px;
    text-align: left;
    border: 1px solid #ddd;
  }

  .reports-table th {
    background-color: #e0f7fa;
    font-weight: bold;
    color: #00796b;
  }

  .reports-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  .reports-table tbody tr:hover {
    background-color: #f1f1f1;
  }

  .reports-table a {
    color: #00796b;
    text-decoration: none;
  }

  .reports-table a:hover {
    text-decoration: underline;
  }

  .reports-table .actions {
    display: flex;
    gap: 10px;
  }

  .reports-table .actions span {
    cursor: pointer;
    font-size: 18px;
  }
  .calendar-container {
    display: flex;
    justify-content: center;
  }

  textarea {
    resize: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 8px;
    width: 100%;
  }

  .duty-roaster-row {
    vertical-align: middle;
  }

  .ant-table-tbody > tr > td {
    vertical-align: middle;
  }

  .duty-roaster-table {
    max-height: 500px;
    overflow: scroll;
  }

  .calendar {
    cursor: pointer;
  }
`;
