import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Table } from "antd";
import { Image } from "antd"; // Import the Image component from Ant Design
import ImageUploadModel from "../../../../components/ImageUploadModel/ImageUploadModel";
import { useDispatch, useSelector } from "react-redux";
import {
  addAttendance,
  addDocEmployeeAPI,
  addDutyRoaster,
  downloadAttendance,
  getAllDocEmployees,
  updateAttendance,
} from "../../../../redux/slices/doctor/doctorService";
import { toast } from "react-toastify";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { AttendanceStyled } from "./Attendance.styled";
import { ReactComponent as Calendaricon } from "../../../../assets/icons/calendar.svg";
import { Select, Button as AntdButton } from "antd";

const { Option } = Select;

const AttendanceRow = ({ employee }: any) => {
  const dispatch = useDispatch();
  console.log("employee : ", employee);

  // State for attendance
  const [attendance, setAttendance] = useState<any>(null); // Attendance for the current date
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [overtimeHours, setOvertimeHours] = useState<string>("0");
  const [overtimeMinutes, setOvertimeMinutes] = useState<string>("0");
  const [isOnLeave, setIsOnLeave] = useState(false);

  // Generate hours and minutes options
  const hoursOptions = Array.from({ length: 11 }, (_, i) => i.toString());
  const minutesOptions = Array.from({ length: 60 }, (_, i) =>
    i.toString().padStart(2, "0")
  );

  // Find today's attendance from the employee's attendance array
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0]; // YYYY-MM-DD
    const todaysAttendance = employee?.attendances?.find(
      (record: any) => record.date.split("T")[0] === today
    );

    if (todaysAttendance) {
      setAttendance(todaysAttendance);
      setCheckIn(todaysAttendance.checkin_time || "");
      setCheckOut(todaysAttendance.checkout_time || "");
      setIsOnLeave(todaysAttendance.status === "absent");
      if (todaysAttendance.overtime) {
        const [hours, minutes] = todaysAttendance.overtime.split(":");
        setOvertimeHours(hours);
        setOvertimeMinutes(minutes);
      }
    }
  }, [employee]);

  // Save new attendance
  const handleSave = async () => {
    const attendanceData = {
      employee_id: employee?.id || null,
      date: new Date().toISOString().split("T")[0],
      status: isOnLeave ? "absent" : "present",
      checkin_time: checkIn || null,
      checkout_time: checkOut || null,
      overtime: `${overtimeHours.padStart(2, "0")}:${overtimeMinutes.padStart(
        2,
        "0"
      )}`,
    };

    const res = (await dispatch(addAttendance(attendanceData))) as any;
    if (res?.error) {
      toast.error(res?.error?.message || "Unknown Error Occured");
    } else {
      toast.success("Attendance saved successfully");
      setAttendance(attendanceData); // Update state with the saved data
    }
  };

  // Update existing attendance
  const handleUpdate = async () => {
    let updateBody;
    if (isOnLeave) {
      updateBody = {
        id: attendance?.id,
        payload: {
          status: isOnLeave ? "absent" : "present",
        },
      };
    } else {
      updateBody = {
        id: attendance?.id,
        payload: {
          status: isOnLeave ? "absent" : "present",
          checkin_time: checkIn || null,
          checkout_time: checkOut || null,
          overtime: `${overtimeHours.padStart(
            2,
            "0"
          )}:${overtimeMinutes.padStart(2, "0")}`,
        },
      };
    }
    const res = (await dispatch(updateAttendance(updateBody))) as any;
    if (res?.error) {
      toast.error(res?.error?.message || "Unknown Error Occured");
      return;
    } else {
      toast.success("Attendance Updated successfully");
    }
  };

  return (
    <div className="attendance-row">
      <div className="name">{employee?.name}</div>

      {/* Check-In Time Input */}
      <div className="check-in">
        <label>Check In:</label>
        <input
          type="time"
          value={checkIn}
          onChange={(e) => setCheckIn(e.target.value)}
          disabled={isOnLeave} // Disable inputs if on leave
        />
      </div>

      {/* Check-Out Time Input */}
      <div className="check-out">
        <label>Check Out:</label>
        <input
          type="time"
          value={checkOut}
          onChange={(e) => setCheckOut(e.target.value)}
          disabled={isOnLeave}
        />
      </div>

      {/* Overtime Section */}
      <div className="over-time">
        <label>Overtime:</label>
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          {/* Hours Select */}
          <Select
            showSearch
            placeholder="Hours"
            value={overtimeHours}
            onChange={(value) => setOvertimeHours(value)}
            style={{ width: 120 }}
            disabled={isOnLeave}
          >
            {hoursOptions.map((hour) => (
              <Option key={hour} value={hour}>
                {hour} Hours
              </Option>
            ))}
          </Select>

          {/* Minutes Select */}
          <Select
            showSearch
            placeholder="Minutes"
            value={overtimeMinutes}
            onChange={(value) => setOvertimeMinutes(value)}
            style={{ width: 120 }}
            disabled={isOnLeave}
          >
            {minutesOptions.map((minute) => (
              <Option key={minute} value={minute}>
                {minute} Minutes
              </Option>
            ))}
          </Select>
        </div>
      </div>

      {/* Mark Leave */}
      <div className="mark-leave">
        <input
          type="checkbox"
          id={`leave-${employee?.name}`}
          checked={isOnLeave}
          onChange={(e) => setIsOnLeave(e.target.checked)}
        />
        <label htmlFor={`leave-${employee?.name}`}>Mark Leave</label>
      </div>

      {/* Save/Update Button */}
      <div className="savebtn">
        {attendance ? (
          <AntdButton className="update" onClick={handleUpdate}>
            Update
          </AntdButton>
        ) : (
          <AntdButton className="save" onClick={handleSave}>
            Save
          </AntdButton>
        )}
      </div>
    </div>
  );
};

const MarkAttendance = ({ doctorId, attendance }: any) => {
  const [showAddEmployee, setShowAddEmployee] = useState(false);
  const [showDutyRoaster, setShowDutyRoaster] = useState(false);
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const { allEmployees } = useSelector((ReduxState: any) => ReduxState.doctor);

  const [date, setDate] = useState<any>(new Date());

  const dispatch = useDispatch();

  // State to hold form input values
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");

  useEffect(() => {
    getEmployees();
  }, [doctorId]);

  const getEmployees = () => {
    if (!doctorId) {
      return;
    }
    dispatch(getAllDocEmployees(doctorId));
  };

  const handleAddEmployee = () => setShowAddEmployee(true);
  const handleDutyRoaster = () => setShowDutyRoaster(true);
  const handleClose = () => {
    setShowAddEmployee(false);
    setShowDutyRoaster(false);
  };

  const handleImageUploadClose = () => setShowImageUpload(false);
  const handleImageUploadSuccess = (img: any) => {
    console.log("imgUrl", img?.data?.user?.image);
    setImgUrl(img?.data?.user?.image);
  };

  const [dutyRoasterData, setDutyRoasterData] = useState<any>([]);
  const [showDateModal, setShowDateModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>("Pick a date");
  const [renderedDate, setRenderedDate] = useState<any>("Pick a date");
  const toggleDateModal = () => {
    setShowDateModal(!showDateModal);
  };

  const handleDateChange = (date: any) => {
    const timezoneOffset = date.getTimezoneOffset() * 60000; // Adjust for timezone
    const adjustedDate = new Date(date.getTime() - timezoneOffset);

    const day = String(adjustedDate.getDate()).padStart(2, "0");
    const month = String(adjustedDate.getMonth() + 1).padStart(2, "0");
    const year = adjustedDate.getFullYear();

    const options: Intl.DateTimeFormatOptions = { weekday: "long" };
    const weekday = adjustedDate.toLocaleDateString("en-US", options);

    const formattedText = `Date: ${day}-${month}-${year} ${weekday}`;
    const newSelectedDate = `${year}-${month}-${day}`;

    setSelectedDate(newSelectedDate);
    setRenderedDate(formattedText);

    if (formattedText) {
      setShowDateModal(false);
      getDutyRosterData(newSelectedDate); // Pass the updated date directly
    }
  };

  // Populate duty roaster data dynamically from allEmployees
  useEffect(() => {
    getDutyRosterData();
  }, [allEmployees]);

  const getDutyRosterData = (updatedDate?: string) => {
    const dateToUse = updatedDate || selectedDate; // Use the parameter if passed, fallback to state

    if (allEmployees?.length) {
      const updatedData = allEmployees.map((employee: any) => {
        const rosterForSelectedDate = employee?.duty_rosters.find(
          (roster: any) =>
            new Date(roster.date).toISOString().split("T")[0] === dateToUse
        );

        return {
          key: employee?.id.toString(),
          employee_id: employee?.id,
          name: employee?.name,
          shift_time: rosterForSelectedDate
            ? {
                start: rosterForSelectedDate?.shift_time.split("-")[0],
                end: rosterForSelectedDate?.shift_time.split("-")[1],
              }
            : { start: "00:00", end: "00:00" },
          role_responsibilities: rosterForSelectedDate
            ? rosterForSelectedDate?.role_responsibilities
            : "",
        };
      });

      setDutyRoasterData(updatedData);
    }
  };

  // Update role responsibilities dynamically for a specific row
  const updateRoleResponsibilities = (key: string, newRole: string) => {
    const updatedData = dutyRoasterData.map((row: any) =>
      row.key === key ? { ...row, role_responsibilities: newRole } : row
    );
    setDutyRoasterData(updatedData);
  };

  const updateShiftAndRole = (key: string, field: string, value: any) => {
    const updatedData = dutyRoasterData.map((row: any) =>
      row.key === key
        ? {
            ...row,
            [field]:
              field === "shift_time" ? { ...row.shift_time, ...value } : value,
          }
        : row
    );
    setDutyRoasterData(updatedData);
  };

  const saveDutyRoasterData = async () => {
    if (selectedDate === "Pick a date") {
      toast.error("Please Select a proper date");
      return;
    }

    const formattedData = dutyRoasterData.map((row: any) => ({
      employee_id: row.employee_id,
      shift_time: `${row.shift_time.start}-${row.shift_time.end}`,
      role_responsibilities: row.role_responsibilities,
      date: selectedDate,
    }));

    console.log("Duty Roaster Data:", formattedData);
    const res = (await dispatch(addDutyRoaster(formattedData))) as any;

    if (res?.error) {
      toast.error(res?.error?.message || "Unknown Error Occured");
    } else {
      toast.success("Duty Roaster Saved Successfully");
      dispatch(getAllDocEmployees(doctorId));
      setShowDutyRoaster(false);
    }
  };

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "name",
      key: "name",
      render: (text: string) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <img
            src="https://placehold.co/40"
            alt="Employee"
            style={{ borderRadius: "50%", marginRight: "8px", width: "40px" }}
          /> */}
          {text}
        </div>
      ),
    },
    {
      title: "Shift Time",
      key: "shift_time",
      render: (text: any, record: any) => {
        console.log("record : ", record);
        return (
          <div style={{ display: "flex", gap: "8px" }}>
            <input
              type="time"
              value={record.shift_time.start}
              onChange={(e) =>
                updateShiftAndRole(record.key, "shift_time", {
                  start: e.target.value,
                })
              }
            />
            <input
              type="time"
              value={record.shift_time.end}
              onChange={(e) =>
                updateShiftAndRole(record.key, "shift_time", {
                  end: e.target.value,
                })
              }
            />
          </div>
        );
      },
    },
    {
      title: "Role Responsibilities",
      dataIndex: "role_responsibilities",
      key: "role_responsibilities",
      render: (text: string, record: any) => (
        <textarea
          rows={2}
          placeholder="Enter role responsibilities"
          value={record.role_responsibilities}
          onChange={(e) =>
            updateRoleResponsibilities(record.key, e.target.value)
          }
          style={{ width: "100%" }}
        />
      ),
    },

    // {
    //   title: "Actions",
    //   key: "actions",
    //   render: (text: any, record: any) => (
    //     <Button
    //       variant="success"
    //       onClick={() => alert(`Saved! ${record.name}'s role: ${record.role}`)}
    //     >
    //       ✓ Save
    //     </Button>
    //   ),
    // },
  ];

  const handleAddEmp = async () => {
    const empBody = {
      doctor_id: parseInt(doctorId),
      name: name,
      email: email,
      phone: contact,
      // role: role,
      image: imgUrl,
    };
    console.log("empBody : ", empBody);
    const res = (await dispatch(addDocEmployeeAPI(empBody))) as any;
    if (res?.error) {
      toast.error(res?.error?.message || "Unknown error occurred");
      return;
    } else {
      toast.success("Employee Added Successfully");
      handleClose();
      getEmployees();
      return;
    }
  };

  const downloadAttendanceExcel = async () => {
    const todayDate = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

    const body = {
      id: doctorId,
      date: todayDate,
    };

    const res = (await dispatch(downloadAttendance(body))) as any;
    console.log("payload : ", res?.payload);

    if (res?.error) {
      toast.error(res?.error?.message || "Unknown error occurred");
      return;
    } else {
      const csvData = res?.payload;

      if (csvData) {
        const filename = `Attendance_${todayDate}.csv`;
        downloadCSV(csvData, filename);
        toast.success("Attendance Downloaded Successfully");
      } else {
        toast.error("No data found to download.");
      }
    }
  };

  // Utility function to download data as CSV
  const downloadCSV = (csvString: any, filename: any) => {
    const blob = new Blob([csvString], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <AttendanceStyled>
      <div className="attendance-list">
        <div className="p-2 d-flex flex-row justify-content-between">
          <div className="d-flex flex-row gap-2">
            <Button onClick={handleAddEmployee}>Add Employee</Button>
            <Button onClick={handleDutyRoaster}>Duty Roaster</Button>
          </div>
          <Button onClick={downloadAttendanceExcel} variant="outline-primary">
            Export as Excel
          </Button>
        </div>
        <div className="list-heading">
          <div className="Date">{new Date().toLocaleDateString("en-GB")}</div>
          <div className="listhead">Mark Attendance</div>
        </div>
        {allEmployees &&
          allEmployees.map((employee: any, index: number) => (
            <AttendanceRow key={employee?.id} employee={employee} />
          ))}

        {/* Add Employee Modal */}
        <Modal show={showAddEmployee} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add Employee</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Email ID</label>
                <input
                  type="email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Contact Number</label>
                <input
                  type="text"
                  className="form-control"
                  value={contact}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only digits and limit input to 10 characters
                    if (/^\d*$/.test(value) && value.length <= 10) {
                      setContact(value);
                    }
                  }}
                />
              </div>

              <div className="form-group">
                <Button
                  variant="secondary"
                  onClick={() => setShowImageUpload(true)}
                >
                  Upload Image
                </Button>

                {/* Display the image if imgUrl exists */}
                {imgUrl && (
                  <Image
                    src={imgUrl}
                    alt="Uploaded Image"
                    width={100}
                    height={100}
                    style={{ marginLeft: "10px", borderRadius: "8px" }}
                  />
                )}
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleAddEmp}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Duty Roaster Modal */}
        <Modal show={showDutyRoaster} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Duty Roaster</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="calendar cursor-pointer">
              <div className="calendar-container mb-3 d-flex flex-row align-items-center gap-2">
                <h5 className="mb-0">
                  {renderedDate === "Pick a date" ? (
                    <span style={{ color: "red", fontWeight: "bold" }}>
                      Please Choose a Date first
                    </span>
                  ) : (
                    <span>{renderedDate}</span>
                  )}
                </h5>
                <Calendaricon onClick={toggleDateModal} />
              </div>
            </div>
            <div
              className="duty-roaster-table"
              style={{ maxHeight: "500px", overflow: "scroll" }}
            >
              <Table
                columns={columns}
                dataSource={dutyRoasterData}
                pagination={false}
                rowClassName={() => "duty-roaster-row"}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDutyRoaster(false)}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={saveDutyRoasterData}>
              Save Duty Roaster
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Image Upload Modal */}
        <ImageUploadModel
          show={showImageUpload}
          handleClose={handleImageUploadClose}
          handleImageUploadSuccess={handleImageUploadSuccess}
        />

        <Modal
          show={showDateModal}
          onHide={toggleDateModal}
          centered
          className="custom-modal"
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <Calendar onChange={handleDateChange} />
          </Modal.Body>
        </Modal>
      </div>
    </AttendanceStyled>
  );
};

export default MarkAttendance;
