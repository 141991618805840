import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP,  } from "../../../config";
import { post, put , get, patch} from "../../../library/Requests/helpers";

import {getEyeHospitalsQuery,getEyeCategoriesQuery,
   getGymCategoriesQuery,getGymVendorsQuery} from "../../../Scenes/graphqlConfig";




export const getEyeCategoriesAPI = createAsyncThunk(
  "auth/getEyeCategoriesAPI",
  async (body: any) => await post(`${SERVER_IP}/graphql`,  JSON.stringify(getEyeCategoriesQuery(body)))
);
export const getEyeHospitalsAPI = createAsyncThunk(
  "auth/getEyeHospitalsAPI",
  async (body: any) => await post(`${SERVER_IP}/graphql`,  JSON.stringify(getEyeHospitalsQuery(body)))
);

export const getAmbulanceList = createAsyncThunk(
  "auth/getAmbulanceList",
  async (body: any) => await patch(`${SERVER_IP}/api/v1/test/ambulance`,  body)
);
export const getGymVendorsAPI = createAsyncThunk(
  "auth/getGymVendorsAPI",
  async (body: any) => await post(`${SERVER_IP}/graphql`,  JSON.stringify(getGymVendorsQuery(body)))
);
export const getCategoriesAPI = createAsyncThunk(
  
  "auth/getCategoriesAPI",
  async (body: any) => await post(`${SERVER_IP}/graphql`,  JSON.stringify(getGymCategoriesQuery(body)))
);

export const getUniversalSearchData = createAsyncThunk(
  "auth/getUniversalSearchData",
  async (body: any) => {
    let url = `${SERVER_IP}/api/v1/config/search`;
    if (body?.type) {
      url += `?type=${body?.type}`;
      const payload = {
        searchText: body?.searchText,
      };
      return await post(url, payload);
    } else {
      return await post(url, body);
    }
  }
);

export const getReportOrderAPI = createAsyncThunk(
  "auth/getReportOrderAPI",
  async (bookingId: any) =>
    await get(`${SERVER_IP}/api/v1/booking/${bookingId}/additional`)
);